// Here you can add other styles

body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}

.form-control-sm {
    min-height: 38px;
}

.table-container {
    overflow-y: auto;
    max-height: 68vh;
}

.topbar-buttons-container {
    white-space: nowrap;
    overflow-x: auto;
}

.nav-link {
  padding: 10px 15px;
  border-radius: 8px;
  transition: background-color 0.3s ease, border-radius 0.3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 12px;
  }
}

.linearIndeterminateContainer {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: #fff; //#e0e0e0;
  border-radius: 0px;
  overflow: hidden;
}

.linearIndeterminateBar {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #321fdb;
  border-radius: 0px;
  animation: linear-progress 1.5s infinite;
  transform-origin: left;
}

@keyframes linear-progress {
  0% {
    transform: scaleX(0);
    opacity: 1;
  }
  50% {
    transform: scaleX(0.5);
    opacity: 0.6;
  }
  100% {
    transform: scaleX(1);
    opacity: 0;
  }
}

::-webkit-scrollbar {
    width: 8px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: #fff;
    width: 5px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #9da5b1;
    border-radius: 10px;
}

::-webkit-file-upload-button {
    min-height: 37px;
}
